<template>
  <div class="login-container">
    <div class="login-image">
      <v-iconFontSymbol
        :icon="'iconlogo_blue'"
        :style="{ 'font-size': '2rem', height: '110px' }"
      />
    </div>
    <div class="login-form">
      <van-field
        v-model="state.telephone"
        type="tel"
        placeholder="手机号"
        center
        maxlength="11"
      >
        <template #left-icon>
          <v-iconFontSymbol
            :icon="'iconphone_number'"
            :style="{ 'font-size': '0.15rem' }"
          />
        </template>
      </van-field>

      <div class="message-container">
        <van-field
          v-model="state.mess_code"
          type="tel"
          placeholder="验证码"
          center
        >
          <template #left-icon>
            <v-iconFontSymbol
              :icon="'iconpassword'"
              :style="{ 'font-size': '0.15rem' }"
            />
          </template>
        </van-field>
        <div class="button-container-message">
          <div
            class="button-message"
            @click="getMessageCode"
            v-show="state.show"
          >
            获取验证码
          </div>

          <div class="button-message" v-show="!state.show">
            {{ state.count }}s
          </div>
        </div>
      </div>

      <div class="button-container">
        <div @click="toHome">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";

import userInfoApi from "@/api/userInfo";
import IconFontSymbol from "@/components/IconFontSymbol.vue";

export default {
  name: "Login",
  components: {
    "v-iconFontSymbol": IconFontSymbol,
  },
  setup() {
    const state = reactive({
      telephone: "",
      mess_code: "",
      show: true,
      count: "",
    });
    const { proxy } = getCurrentInstance();
    const router = useRouter();

    const getMessage = async (telephone) => {
      await proxy.$request.handleFetchGet(
        `${userInfoApi.mess_code}${telephone}`
      );
      Toast("验证码发送成功");
      const TIME_COUNT = 60;
      if (!state.timer) {
        state.count = TIME_COUNT;
        state.show = false;
        state.timer = setInterval(() => {
          if (state.count > 0 && state.count <= TIME_COUNT) {
            state.count--;
          } else {
            state.show = true;
            clearInterval(state.timer);
            state.timer = null;
          }
        }, 1000);
      }
    };

    const toLogin = async (params) => {
      const res = await proxy.$request.handleFetchPostJson(
        userInfoApi.mcode_login,
        params
      );
      proxy.$cookie.setCookie("tokenid", res.token, 25, "/");
      proxy.$store.dispatch("getUserInfoAction");
      router.replace({
        name: "home",
      });
    };

    const toHome = () => {
      if (state.telephone == "") {
        Toast("请输入手机号");
        return;
      }
      if (state.mess_code == "") {
        Toast("请输入验证码");
        return;
      }
      let params = {
        telephone: state.telephone,
        mess_code: state.mess_code,
        union_id: proxy.$cookie.getCookie("unionid"),
      };
      toLogin(params);
    };

    const getMessageCode = () => {
      if (state.telephone == "") {
        Toast("请输入手机号");
        return false;
      }
      getMessage(state.telephone);
    };

    return { state, toHome, getMessageCode };
  },
};
</script>
